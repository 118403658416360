import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PraxisGrid from "../components/praxis-grid";

function IndexPage() {
  return (
    <Layout>
      <Seo
        title="Privatpraxis für Psychotherapie Berlin-Buch | Antje Mützel"
        description="Wilkommen in meiner Privatpraxis für Verhaltenstherapie in Berlin-Buch. Psychotherapie für Privatpatienten, Beihilfe & Selbstzahler. Freie Plätze auf Anfrage."
      />

      <div id="hero__wrapper" className="relative">
        <div className="w-full motion-reduce:h-96 motion-safe:h-[32rem] max-h-[32rem]">
          <StaticImage
            className="w-full h-full"
            src="../assets/images/antje-muetzel-psychotherapie-berlin-1.jpg"
            alt="Heller freundlicher Praxisraum für Psychotherapie"
          />
          <div
            id="hero__overlay"
            className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center"
          >
            <div className="w-full text-center text-white opacity-0 hero__title">
              <h1 className="lg:text-4xl">
                Privatpraxis für <br />
                kognitive Verhaltenstherapie
              </h1>
              <div>M. Sc. Psych. Antje Mützel</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 mb-4 md:mb-16 md:p-12 generic-page">
        <div className="mx-auto mb-4 md:mb-6 max-w-prose">
          <h2 className="text-center">
            Herzlich Willkommen <br className="hidden sm:block" />
            in meiner Privatpraxis für kognitive Verhaltenstherapie in
            Berlin-Buch.
          </h2>
          <p className="text-lg">
            Mein Angebot umfasst Psychotherapie, Coaching und Paartherapie.
          </p>
          <p>
            Als staatlich approbierte psychologische Psychotherapeutin biete ich
            Ihnen:
          </p>
          <ul className="list-disc list-inside">
            <li>
              professionelle Hilfe bei psychischen Erkrankungen und Krisen
            </li>
            <li>Förderung von Selbstsicherheit und Selbstwert</li>
            <li>Stärkung sozialer Kompetenzen</li>
            <li>Unterstützung in Phasen großer Veränderung</li>
          </ul>
        </div>

        {/* <PraxisGrid /> */}

        <div className="mx-auto mb-4 max-w-prose">
          <p>
            Ihre Selbstbestimmung ist mir sehr wichtig, meine Arbeit fußt auf
            den Werten Vertrauen, Toleranz, Offenheit und Transparenz. Für mich
            bedeutet Psychotherapie immer auch Hilfe zur Selbsthilfe, ich
            unterstütze Sie professionell auf Ihrem persönlichen Weg der
            Veränderung.
          </p>
          <p>
            Alle Interventionen werden individuell auf Sie abgestimmt, denn jede
            Person bringt ihre ganz eigene Lerngeschichte und auch einzigartige
            persönliche Stärken mit. In ressourcenorientierter Zusammenarbeit
            finden wir Wege, damit Sie Schritt für Schritt Ihre Ziele erreichen
            können.
          </p>
          <p>
            Verschaffen Sie sich gern auf meiner Website einen ersten Überblick
            über mein Angebot, ich freue mich auf Ihre Kontaktaufnahme.
          </p>
          <p>
            In meiner Praxis ist jeder Mensch, unabhängig von Geschlecht, Alter,
            Herkunft oder Religion herzlich willkommen.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
